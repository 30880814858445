"use client";
import React, { useId, useState } from "react";
import { signIn } from "next-auth/react";
import { useSearchParams } from "next/navigation";
import Link from "next/link";
import LinkIcon from "@icons/link.svg";
import Button from "@components/ui/Button";
import Hero from "@components/Hero";
import { auth } from "@utils/auth";

type Errors = "Invalid credentials";

type LoginError = {
  error: {
    message: string;
    type: Errors;
  };
};

type Props = {};

function LoginForm({}: Props) {
  const searchParams = useSearchParams();
  const id = useId();

  return (
    <div className="login">
      <p className="login__info">
        Geben Sie Ihren Benutzernamen und Ihr Passwort ein, um sich an der
        Website anzumelden
      </p>
      {searchParams.get("error") === "CredentialsSignin" ? (
        <p className="login__error">
          Während der Anmeldung ist ein Fehler aufgetreten. Wahrscheinlich haben
          Sie Ihren Benutzernamen oder das Passwort falsch eingegeben.
          Vergewissern Sie sich, dass Sie beide Angaben korrekt eingegeben haben
          - Groß-/Kleinschreibung wird unterschieden. Eine andere Möglichkeit
          ist, dass Sie eventuell die Cookies in Ihrem Web-Browser deaktiviert
          haben.
        </p>
      ) : null}
      <h2>Jetzt anmelden</h2>
      <form
        className="login-form"
        autoComplete="off"
        onSubmit={async (e) => {
          e.preventDefault();

          const formData = new FormData(e.currentTarget);

          const login = formData.get("login");
          const password = formData.get("password");

          if (login && password) {
            const res = await signIn("credentials", {
              login,
              password,
              redirect: true,
              callbackUrl: "/intern",
            });
          }
        }}
      >
        <div className="login-form__element">
          <label htmlFor={`${id}-login`}>
            E-Mail-Adresse<sup>*</sup>
          </label>
          <input type="text" name="login" id={`${id}-login`} required />
        </div>
        <div className="login-form__element">
          <label htmlFor={`${id}-password`}>
            Kennwort<sup>*</sup>
          </label>
          <input
            type="password"
            name="password"
            id={`${id}-password`}
            required
          />
        </div>
        <div className="login-form__element">
          <Button type="submit" primary>
            Login
          </Button>
        </div>
        <p className="form__required-fields">
          <sup>*</sup> Pflichtfelder
        </p>
      </form>
      <h2>Erstmalig Zugangsdaten zum internen Bereich beantragen</h2>
      <p>
        MitarbeiterInnen von Institutionen, die als NRW.Innovationspartner
        gelistet sind, haben hier die Möglichkeit, Zugangsdaten für den internen
        Bereich anzufragen.
      </p>
      <p>
        <Link href="/login/zugangsdaten-beantragen">
          Bitte klicken Sie hier. <LinkIcon height={16} />
        </Link>
      </p>
      <h2>Passwort vergessen / zurücksetzen</h2>
      <p>
        Wenn Sie bereits über einen Zugang verfügen, aber Ihr Passwort vergessen
        haben oder zurücksetzen möchten,{" "}
        <Link href="/login/kennwort-zuruecksetzen">
          klicken Sie bitte hier.
          <LinkIcon height={16} />
        </Link>
      </p>
    </div>
  );
}

export default LoginForm;
